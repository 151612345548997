/* global EM */
import React, { Component } from 'react';
import PageTitle from '../components/PageTitle';
import StaffingEmployeePreferences from '../entities/preferences/StaffingEmployeePreferences';
import StaffingEmployeeToolsPanel from '../components/Staffing/StaffingEmployeeToolsPanel';
import StaffingEmployeeRow from '../components/Staffing/StaffingEmployeeRow';
import StaffingGroupTotalRow from '../components/Staffing/StaffingGroupTotalRow';
import StaffingTimeline from '../components/Staffing/StaffingTimeline';
import AddEmployeeAssignmentDialog from '../components/Staffing/AddEmployeeAssignmentDialog2';
import ReconcileDatesModal from '../components/Staffing/ReconcileDatesModal';
import IdentifyOrphansModal from '../components/Staffing/IdentifyOrphansModal';
import Dates from '../util/Dates';
import _ from 'underscore';
import StaffingReportOptionsDialog from '../components/Staffing/StaffingReportOptionsDialog';
import Routes from '../app/Routes';
import AssignmentGroupPanel from '../components/Staffing/AssignmentGroupPanel';
import AssignmentEditDialog from '../components/Staffing/AssignmentEditDialog';
import StaffingUtil from '../util/StaffingUtil';


export default class StaffingEmployee extends Component {
    constructor(props) {
        super(props);

        if (!EM.hasFeature('staffing')) EM.denyFeature();

        this._isMounted = false;
        this.defaultPrefs = new StaffingEmployeePreferences('default');
        this.areToolsOpenPref = this.defaultPrefs.preferences.asFlag('tools-panel', 'open');
        this.defaultRangeInYears = 2;

        let cssVar = getComputedStyle(document.documentElement).getPropertyValue('--cell-width');
        this.cellWidth = parseInt(cssVar.trim());
        if (isNaN(this.cellWidth)) this.cellWidth = 40;

        let cssVar2 = getComputedStyle(document.documentElement).getPropertyValue('--row-height');
        this.rowHeight = parseInt(cssVar2.trim());
        if (isNaN(this.rowHeight)) this.rowHeight = 30;

        this.employees = [];

        this.state = {
            preferences: this.defaultPrefs,
            areToolsOpen: this.areToolsOpenPref(),
            groups: {},
            range: {},
            assignments: [],
            assignmentGroups: {},
            selectedItem: null,
            selectedEmployee: null,
            addAssignmentsDialogOpen: false,
            showReconcileDates: false,
            showIdentifyOrphans: false,
            reportModalOpen: false,
            requestModalOpen: false,
            assignmentGroupPanelShowing: null,
            forcedGrouping: null,
            assignmentToEdit: null
        }

        this.onFilterToggle = this.onFilterToggle.bind(this);
        this.onPrefSetChanged = this.onPrefSetChanged.bind(this);
        this.groupAndFilterData = this.groupAndFilterData.bind(this);
        this.onUpdateAssignment = this.onUpdateAssignment.bind(this);
        this.onDeleteAssignment = this.onDeleteAssignment.bind(this);
        this.onToggleAssignmentLock = this.onToggleAssignmentLock.bind(this);
        this.onAddAssignment = this.onAddAssignment.bind(this);
        this.onAddClicked = this.onAddClicked.bind(this);
        this.onDuplicateAssignment = this.onDuplicateAssignment.bind(this);
        this.processUpdateQueue = this.processUpdateQueue.bind(this);
        this.onCloseReconcileDialog = this.onCloseReconcileDialog.bind(this);
        this.onCloseIdentifyOrphans = this.onCloseIdentifyOrphans.bind(this);
        this.reloadAssignments = this.reloadAssignments.bind(this);
        this.onReportModalClose = this.onReportModalClose.bind(this);
        this.onGenerateReport = this.onGenerateReport.bind(this);
        this.onRequestModalClose = this.onRequestModalClose.bind(this);
        this.onBulkUpdateAssignments = this.onBulkUpdateAssignments.bind(this);
        this.onEditInitiated = this.onEditInitiated.bind(this);
        this.onEditExecuted = this.onEditExecuted.bind(this);

        this.updateQueue = {};
        window.setInterval(this.processUpdateQueue, 15000);
        window.addEventListener('beforeunload', (event) => {
            let toUpdate = Object.values(this.updateQueue);
            if (toUpdate.length > 0) {
                event.preventDefault();
                event.returnValue = '';
            }
        });
    }

    componentDidMount() {
        if (!EM.hasFeature('staffing')) return EM.denyFeature();
        this._isMounted = true;

        let self = this;
        EM.loadEntities([EM.resourceRequests, EM.settings, EM.activities, EM.roles, EM.organizations, EM.departments, EM.employees, EM.assignments, EM.actuals, EM.referenceTables]).then(() => {
            self.rowLimit = (EM.getTenantOption('staffingVisualizationRowLimit') || 1000);
            EM.schedules.loadDefaultItem().then((defaultSchedule) => {
                if (!defaultSchedule) return;
                
            });
            self.onLoadInitial();
            let assignments = StaffingUtil.processAssignments(EM.assignments.get());
            self.setAssignmentState(assignments);

            EM.schedules.loadDefaultItem().then((defaultSchedule) => {
                EM.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                    let fileContents = EM.schedules.getFile(defaultSchedule.ScheduleId);
                    if (fileContents) {
                        self.setState({ defaultScheduleContent: fileContents, defaultSchedule });
                    }
                });
            });

        });
      
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onLoadInitial() {
        this.filterValues = {
            Name: {},
            JobTitle: {},
            Role: {},
            Department: {},
            Organization: {},
            Region: {},
            SubRegion: {},
            Email: {},
            EmployeeNumber: {},
            ManagerNumber: {},
            CostCenter: {},
            EmployeeType: {},
            PositionStatus: {},
            Contractor: {},
            Custom1: {},
            Custom2: {},
            Custom3: {},
            Custom4: {},
            Custom5: {}
        };

        this.filterKeys = Object.keys(this.filterValues);

        this.employees = _.sortBy(EM.employees.get(), (emp) => {
            emp.NameLf = emp.LastName + ',' + emp.FirstName;
            return emp.NameLf;
        }).mapFiltered((emp) => {
            let role = EM.roles.byId(emp.RoleId);
            if (!role) return null; //This is used to support Role limits placed on child domains - JS
            let dept = null;
            if (role) dept = EM.departments.byId(role.DepartmentId);
            let org = null;
            if (dept) org = EM.organizations.byId(dept.OrganizationId);

            let output = Object.assign({}, emp, {
                Name: emp.FirstName + ' ' + emp.LastName,
                Role: role ? role.Name : null,
                DepartmentId: dept ? dept.DepartmentId : null,
                Department: dept ? dept.Name : null,
                OrganizationId: org ? org.OrganizationId : null,
                Organization: org ? org.Name : null,
            });

            this.filterKeys.forEach((key) => {
                if (key === 'Contractor') {
                    let isContractor = (output[key] === false || output[key] == null) ? 'No' : 'Yes';
                    this.filterValues[key][isContractor] = true;
                }
                else if (output[key]) this.filterValues[key][output[key]] = true;
            });

            return output;
        });

        this.filterKeys.forEach((key) => {
            if (key === 'Name') {
                this.filterValues[key] = Object.keys(this.filterValues[key]);
            }
            else {
                this.filterValues[key] = Object.keys(this.filterValues[key]).sort();
            }
        });

        this.groupAndFilterData(this.defaultPrefs);

        window.setTimeout(() => {
            let hash = window.location.hash;
            if (hash) {
                try {
                    let element = document.querySelector('#' + CSS.escape(hash.slice(1)));
                    let container = document.querySelector('.staffing-container');
                    if (!element) return;
                    element.scrollIntoView();
                    container.scrollTop = container.scrollTop - 50;
                } catch (e) { }
            }

            if (window.location.search) {
                let qs = Routes.parseQuery();
                let grouping = decodeURIComponent(qs.grouping);
                let key = decodeURIComponent(qs.key);
                if (grouping && key) {
                    this.setState({ assignmentGroupPanelShowing: key, forcedGrouping: grouping }, () => {
                        this.groupAndFilterData(this.defaultPrefs);
                    });
                }
            }
        }, 0);
    }

    groupAndFilterData(nextPreferences) {
        let newState = {
            preferences: nextPreferences,
            selectedItem: null,
            selectedEmployee: null,
        };

        let filteredEmployees = this.employees.filter((employee) => {
            let keys = this.filterKeys;

            if (nextPreferences.employeeSet) {
                if (nextPreferences.employeeSet === 'Active') {
                    if (!employee.Active) return false;
                } else if (nextPreferences.employeeSet === 'Inactive') {
                    if (employee.Active) return false;
                }
            } else {
                if (!employee.Active) {
                    return false;
                }
            }

            if (keys.length === 0) return true;

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let acceptedValues = nextPreferences.get(key);
                if (!acceptedValues) continue;

                let empValue = employee[key];
                if (key === 'Contractor') {
                    empValue = (empValue === false || empValue == null) ? 'No' : 'Yes';
                }
                if (empValue == null || acceptedValues.indexOf(empValue.toString()) === -1) {
                    return false;
                }
            };

            return true;
        });

        let grouping = this.state.forcedGrouping || nextPreferences.grouping;
        newState.groups = _.groupBy(filteredEmployees, grouping);

        let range = Dates.getMonthRangeFromMonthYearStrs(nextPreferences.begin, nextPreferences.end, this.defaultRangeInYears);
        newState.range = Dates.getArrayOfMonths(range[0], range[1], true);
        this.setState(newState);
    }

    onUpdateAssignment(updatedAssignment, bypassSave) {
        if (!bypassSave) this.updateQueue[updatedAssignment.AssignmentId] = updatedAssignment;
        let assignment = StaffingUtil.processSingleAssignment(updatedAssignment);
        let assignments = Object.assign(this.state.assignments, { [assignment.AssignmentId]: assignment });
        this.setAssignmentState(assignments);
    }

    onBulkUpdateAssignments(updatedAssignments, bypassSave) {
        let assignments = Object.assign({}, this.state.assignments);
        updatedAssignments.forEach((updatedAssignment) => {
            if (!bypassSave) this.updateQueue[updatedAssignment.AssignmentId] = updatedAssignment;
            let assignment = StaffingUtil.processSingleAssignment(updatedAssignment);
            assignments[assignment.AssignmentId] = assignment;
        })
        this.setAssignmentState(assignments);
    }

    onDeleteAssignment(deletedAssignmentId) {
        EM.assignments.delete([deletedAssignmentId]);
        let assignments = Object.assign(this.state.assignments, { [deletedAssignmentId]: null });
        this.setAssignmentState(assignments);
    }

    onAddAssignment(assignments) {
        let self = this;

        let lengthTooLong = assignments.find((item) => {
            return item.WorkItemName.length > 100;
        });

        if (lengthTooLong) {
            window.alert(EM.t('staffing.truncationWarning'));
            return;
        }

        EM.assignments.createBulk(assignments).then((createdAssignments) => {
            let assignments = {};
            createdAssignments.data.forEach(createdAssignment => {
                let newAssignment = StaffingUtil.processSingleAssignment(createdAssignment);
                assignments = Object.assign(self.state.assignments, { [newAssignment.AssignmentId]: newAssignment });
            });
            this.setAssignmentState(assignments);
        });
    }

    onToggleAssignmentLock(toggledAssignmentId) {
        let assignment = this.state.assignments[toggledAssignmentId];
        if (!assignment) return;
        let newAssignment = Object.assign({}, assignment, { Status: assignment.Status === 'Locked' ? '' : 'Locked' });
        this.onUpdateAssignment(newAssignment);
    }

    onEditInitiated(editedAssignmentId) {
        let assignment = this.state.assignments[editedAssignmentId];
        if (!assignment) return;
        this.setState({ assignmentToEdit: assignment });
    }

    onEditExecuted(editedAssignment) {
        this.onUpdateAssignment(editedAssignment);
    }

    onDuplicateAssignment(duplicateAssignmentId) {
        let self = this;
        let assignment = this.state.assignments[duplicateAssignmentId];
        if (!assignment) return;
        let dupedAssignment = Object.assign({}, assignment, { Status: 'Assigned' });
        delete dupedAssignment.AssignmentId;

        EM.assignments.create(dupedAssignment).then((createdAssignment) => {
            let newAssignment = StaffingUtil.processSingleAssignment(createdAssignment.data);
            let assignments = Object.assign(self.state.assignments, { [newAssignment.AssignmentId]: newAssignment });
            this.setAssignmentState(assignments);
        });
    }

    setAssignmentState(assignments, additionalState) {
        let assignmentGroups = _.groupBy(Object.values(assignments), 'EmployeeId');
        let newState = { assignments, assignmentGroups };
        if (additionalState) newState = Object.assign(newState, additionalState);
        this.setState(newState);
    }

    onAddClicked() {
        this.setState({ addAssignmentsDialogOpen: true });
    }

    processUpdateQueue() {
        let toUpdate = Object.values(this.updateQueue);
        if (toUpdate.length <= 0) return;
        EM.log('Processing staffing update queue. Objects: ', toUpdate.length);
        let isSilent = true;
        let modsOnly = true;
        EM.assignments.import(Object.values(this.updateQueue), isSilent, modsOnly).then(() => {
            this.updateQueue = {};
        });
    }

    onPrefSetChanged(nextPreferences) {
        this.groupAndFilterData(nextPreferences);
    }

    onFilterToggle() {
        this.areToolsOpenPref(!this.state.areToolsOpen);
        this.setState({ areToolsOpen: !this.state.areToolsOpen });
        EM.triggerWindowResize();
    }

    onCloseReconcileDialog(reloadAssignments) {
        let self = this;
        self.setState({ showReconcileDates: false });

        if (reloadAssignments === true) {
            self.reloadAssignments();
        }
    }

    onCloseIdentifyOrphans(reloadAssignments) {
        let self = this;
        self.setState({ showIdentifyOrphans: false });

        if (reloadAssignments === true) {
            self.reloadAssignments();
        }
    }

    reloadAssignments() {
        let self = this;
        return EM.loadEntities([EM.assignments, EM.employees], true).then(() => {
            let assignments = StaffingUtil.processAssignments(EM.assignments.get());
            self.setAssignmentState(assignments);
        });
    }

    onReportModalClose() {
        this.setState({ reportModalOpen: false });
    }
    onRequestModalClose() {
        this.setState({ requestModalOpen: false });
    }
    onGenerateReport(options) {
        let reportType = 'employee';
        let currentPreferencesName = this.state.preferences.name;
        let reportAddress = Routes.compose(Routes.client.staffingReport, { DomainName: this.props.domain.Name }, { custom: currentPreferencesName, type: reportType });
        EM.crossPage = Object.assign({}, options, { reportType });
        this.props.history.push(reportAddress);
    }

    render() {
        let showResourceRequests = EM.getSetting("ResourceRequests:Enabled")?.toLowerCase() === "true" ? true : false;
        const account = EM.store?.getState();
        let domain = EM.getActiveDomain();
       let resourceRequest = EM.isStrictlyDomainSubscriber() ?  EM.resourceRequests?.get()?.filter(x => x.RequesterAcknowledged ==null && x.RequesterId == account?.account?.User?.UserId) :  EM.resourceRequests?.get()?.filter(x => x.RecipientId ==null && x.StatusId == 1);
        if (!this.state.range) return null;
        if (!this.state.range.dates) return null;
        let width = (this.cellWidth * this.state.range.dates.length) + StaffingUtil.getHeaderWidth();
        let groupKeys = Object.keys(this.state.groups).sort();
        let groupingKey = this.state.forcedGrouping || this.state.preferences.grouping;
        let groupType = EM.t('employees.columns.' + (groupingKey ? groupingKey.decapitalize() : 'none'));
        let rowCount = 0;
        return (
            <div key="contents" className={"page page-visualization container-fluid staffing " + (this.state.areToolsOpen ? 'tools-mode' : '')}>
                <PageTitle title={EM.t('staffing.employee.title')} bar={true}>
                    <div className="btn-group">
                        <button className="btn btn-sm btn-success" onClick={() => { this.setState({ reportModalOpen: true }); }}>
                            {EM.t('staffing.generateReport')}
                        </button>
                    </div>
                    {EM.isDomainAdmin() && !EM.getSetting('HideReconcileSchedule') ?
                        <div className="btn-group">
                            <button className="btn btn-sm btn-secondary"
                                title="Match assignment dates to default schedule dates"
                                onClick={() => { window.setTimeout(() => { this.setState({ showReconcileDates: true }) }, 0); }}
                            >
                                {EM.t('staffing.reconcile')}
                            </button>
                            <button className="btn btn-sm btn-secondary beta"
                                title="Free up staff assigned to work that no longer exists in the default schedule"
                                onClick={() => { window.setTimeout(() => { this.setState({ showIdentifyOrphans: true }) }, 0); }}
                            >
                                {EM.t('staffing.orphans')}
                            </button>
                        </div>
                        : null}
                    <button className="btn btn-default btn-tools" onClick={this.onFilterToggle}>
                        <i className="fas fa-bars"></i>
                    </button>
                </PageTitle>
                <div className="staffing-container">
                    <div className="staffing-container-content" style={{ width: width }}>
                        <StaffingTimeline range={this.state.range} />
                        {groupKeys.length === 0 ?
                            <div className="group error">
                                <div className="group-header">
                                    <span>{EM.t('util.nodata')}</span>
                                </div>
                            </div>
                            : null}
                        {groupKeys.map((groupKey, groupIndex) => {
                            let group = this.state.groups[groupKey];
                            let fullGroupAssignments = [];
                            let totalGroupAllocation = group.reduce((memo, employee) => memo += (employee.TimeAllocation || 1), 0);
                            let groupKeyDisplay = !groupKey || groupKey === 'null' ? '[Blank]' : groupKey;
                            if (groupKeyDisplay === 'true') {
                                groupKeyDisplay = 'Yes';
                            } else if (groupKeyDisplay === 'false') {
                                groupKeyDisplay = 'No';
                            }
                            if (rowCount > this.rowLimit) return null;
                            return (
                                <div className="group" key={groupKey}>
                                    <div className="group-header">
                                        {groupingKey ?
                                            <button className="btn btn-light btn-sm" onClick={() => {
                                                this.setState({ assignmentGroupPanelShowing: groupKey });
                                            }}>
                                                <i className="fas fa-external-link-alt"></i>
                                            </button>
                                            : <>&nbsp;</>}
                                        <span>
                                            {groupingKey ? <>{groupType}: {groupKeyDisplay}</> : EM.t('staffing.allEmployees')}
                                            <span className="text-muted" style={{ fontWeight: 'normal' }}>
                                                &nbsp;({EM.t('staffing.employees')}: {group.length} / {EM.t('staffing.ftes')}: {totalGroupAllocation.toFixed(2)})
                                            </span>
                                        </span>
                                    </div>
                                    {group.map((employee, empIndex) => {
                                        let employeeAssignments = this.state.assignmentGroups ? this.state.assignmentGroups[employee.EmployeeId] : null;
                                        if (employeeAssignments) {
                                            Array.prototype.push.apply(fullGroupAssignments, employeeAssignments);
                                        }
                                        rowCount++;
                                        if (rowCount > this.rowLimit) return null;
                                        return (
                                            <StaffingEmployeeRow
                                                cellWidth={this.cellWidth}
                                                rowHeight={this.rowHeight}
                                                maxWidth={width - StaffingUtil.getHeaderWidth()}
                                                key={employee.EmployeeId}
                                                employee={employee}
                                                range={this.state.range}
                                                assignments={employeeAssignments}
                                                isActive={this.state.selectedItem === employee.EmployeeId}
                                                onClick={() => {
                                                    let newSelection = this.state.selectedItem === employee.EmployeeId ? null : employee.EmployeeId;
                                                    this.setState({ selectedItem: newSelection, selectedEmployee: employee });
                                                }}
                                                onUpdate={this.onUpdateAssignment}
                                                onDelete={this.onDeleteAssignment}
                                                onAdd={this.onAddClicked}
                                                onDuplicate={this.onDuplicateAssignment}
                                                onToggleLock={this.onToggleAssignmentLock}
                                                onEdit={this.onEditInitiated}
                                            />
                                        );
                                    })}
                                    <StaffingGroupTotalRow
                                        cellWidth={this.cellWidth}
                                        assignments={fullGroupAssignments}
                                        allocation={totalGroupAllocation}
                                        range={this.state.range}
                                    />
                                    {this.state.assignmentGroupPanelShowing === groupKey ?
                                        <AssignmentGroupPanel
                                            title={groupType + ': ' + (groupKey || '(Blank)')}
                                            url={window.location.origin + window.location.pathname + `?grouping=${groupingKey}&key=${groupKey}`}
                                            isOpen={this.state.assignmentGroupPanelShowing === groupKey}
                                            assignments={fullGroupAssignments}
                                            onBulkUpdate={newItems => {
                                                this.onBulkUpdateAssignments(newItems, true);
                                            }}
                                            onSingleUpdate={newItem => {
                                                this.onUpdateAssignment(newItem, true);
                                            }}
                                            onClose={() => {
                                                this.setState({ assignmentGroupPanelShowing: null, forcedGrouping: null });
                                            }}
                                        />
                                        : null}
                                </div>
                            )
                        })}
                        {rowCount > this.rowLimit ?
                            <div>
                                <div className="m-3 alert alert-secondary">{EM.t('staffing.rowLimitExceeded')}</div>
                            </div>
                            : null}
                    </div>
                    <div className={"staffing-container-overlay " + (this.state.selectedItem ? 'active' : '')}
                        onClick={() => {
                            this.setState({ selectedItem: null });
                        }}
                    />
                </div>
                <StaffingEmployeeToolsPanel
                    onFilterClose={this.onFilterToggle}
                    onChange={this.onPrefSetChanged}
                    filterValues={this.filterValues || {}}
                />

                {this.state.selectedEmployee ?
                    <AddEmployeeAssignmentDialog
                        employee={this.state.selectedEmployee}
                        isOpen={this.state.addAssignmentsDialogOpen}
                        range={this.state.range}
                        onAddAssignment={this.onAddAssignment}
                        onClose={() => {
                            this.setState({ addAssignmentsDialogOpen: false });
                        }}
                    />
                    : null}
                {this.state.showReconcileDates ?
                    <ReconcileDatesModal
                        defaultScheduleContent={this.state.defaultScheduleContent}
                        assignments={this.state.assignments}
                        schedule={this.state.defaultSchedule}
                        employees={EM.employees.get()}
                        domain={EM.getActiveDomain()}
                        close={this.onCloseReconcileDialog}
                        isShowing={this.state.showReconcileDates}
                        reloadAssignments={this.reloadAssignments}
                    />
                    : null}
                {this.state.showIdentifyOrphans ?
                    <IdentifyOrphansModal
                        defaultScheduleContent={this.state.defaultScheduleContent}
                        assignments={this.state.assignments}
                        schedule={this.state.defaultSchedule}
                        employees={EM.employees.get()}
                        domain={EM.getActiveDomain()}
                        close={this.onCloseIdentifyOrphans}
                        isShowing={this.state.showIdentifyOrphans}
                        reloadAssignments={this.reloadAssignments}
                    />
                    : null}
                <StaffingReportOptionsDialog
                    onClose={this.onReportModalClose}
                    isOpen={this.state.reportModalOpen}
                    onGenerateReport={this.onGenerateReport}
                    tableTypes={[
                        { value: 'groupedByEmployee', label: EM.t('reports.staffing.tableTypes.groupedByEmployee') },
                        { value: 'groupByCostCenter', label: EM.t('reports.staffing.tableTypes.groupByCostCenter') }
                    ]}
                />
                
                {this.state.assignmentToEdit ?
                    <AssignmentEditDialog
                        isOpen={!!this.state.assignmentToEdit}
                        onClose={() => {
                            this.setState({ assignmentToEdit: null });
                        }}
                        assignment={this.state.assignmentToEdit}
                        onEdit={this.onEditExecuted}
                    />
                    : null}
            </div>
        );
        
    }
}
